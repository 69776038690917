import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
// import DynamicPageTemplate from "./dynamic-page"

import {
  Page,
  MajorColumn,
  MinorColumn,
  FlexWrapMobile
} from "../../components/content-elements"
import GalleryListing from "../../components/gallery_listing"
import md5 from "md5"


const TestimonialsPageTemplate = ({ data, location }) => {
  console.log('TestimonialsPageTemplateTestimonialsPageTemplateTestimonialsPageTemplateTestimonialsPageTemplateTestimonialsPageTemplate')
  console.log(data)
  const { page, testimonials, galleries } = data

  return (
    <Layout location={location}>
      <FlexWrapMobile>
        <MinorColumn><GalleryListing data={galleries} columns={2} /></MinorColumn>
        <MajorColumn>
          <Page page={page} seo={true}>

            <section>
              {(() => {
                if (testimonials.frontmatter.testimonials) {
                  return (
                    testimonials.frontmatter.testimonials.map(entry => {

                        return (
                          <div key={md5(entry.quote)} className=" py-4 px-8 bg-white shadow-lg rounded-lg mb-10">
                            <blockquote className="quote">
                              {/*<h2 className="text-gray-800 text-3xl font-semibold">Quote title</h2>*/}
                              <p className="mt-2 text-primary-dull">{entry.quote}</p>
                              <cite><div className="flex font-semibold justify-end mt-4">{entry.author.name}</div></cite>
                            </blockquote>

                          </div>
                        )

                      }
                    )
                  )
                }
              })()}

            </section>

          </Page>
        </MajorColumn>
      </FlexWrapMobile>
    </Layout>
  )
}

export default TestimonialsPageTemplate

export const pageQuery = graphql`

    fragment TestimonialsFields on MarkdownRemark {
        frontmatter {
            testimonials {
                quote
                author {
                    name
                }
            }
        }
    }
    
    query testimonialsPageById($id: String!) {
        testimonials: markdownRemark(id: {eq: $id}) {
            ...TestimonialsFields
        }
        ...GalleryListFragment
        page: markdownRemark(id: {eq: $id}) {
            ...GeneralDynamciPageFields
        }
        marketing: markdownRemark(id: {eq: $id}) {
            ...SEOFields
            ...MarketingFields
        }
    }
`